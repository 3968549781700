import styled from 'styled-components';

import { Card } from 'styles/elements';
import { easeOutTiming } from 'styles/animation';

const Wrapper = styled.div`
  grid-auto-columns: min-content;
`;

const Project = styled(Card)`
  grid-template-rows: auto 1fr auto;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 8.75rem;
    margin: auto auto 2rem;
    padding-top: 1rem;
  }
`;

const Scale = styled.div`
  grid-auto-flow: column;
  align-items: baseline;
  display: grid;

  > div {
    grid-auto-flow: column;
    justify-content: start;
    align-items: baseline;
    white-space: nowrap;
    column-gap: 0.5rem;
    display: grid;
  }
`;

const ProgressBar = styled.div`
  transition: background-color ${easeOutTiming};
  background-color: var(--secondary-button-background-color);
  border-radius: var(--border-radius);
  height: var(--progress-bar-height);
  margin: 0.375rem 0;
  position: relative;
  overflow: hidden;

  span {
    background-color: ${({ isClosed }) =>
      isClosed ? 'var(--success-color)' : 'var(--primary-color)'};
    transform-origin: left top;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
`;

const StatusDetails = styled.div`
  grid-auto-flow: column;
  align-items: center;
  column-gap: 0.5rem;
  width: fit-content;
  display: grid;

  span {
    font-size: 0.75rem;
    color: var(--secondary-text-color);
  }
`;

const Icon = styled.svg`
  max-width: 1rem;
`;

const Current = styled.p`
  margin-bottom: unset;
`;

const Target = styled.span`
  transition: color ${easeOutTiming};
  color: var(--secondary-text-color);
  font-weight: var(--weight-medium);
  font-size: 0.875rem;
  display: block;
`;

const Subtitle = styled.p`
  font-weight: var(--weight-medium);
  transition: color ${easeOutTiming};
  color: var(--secondary-text-color);
  line-height: 1.1875rem;
  margin: 1rem 0 0.5rem;
`;

const Description = styled.div`
  color: var(--secondary-text-color);
  transition: color ${easeOutTiming};
  font-size: 0.75rem;
`;

const Buttons = styled.div`
  grid-auto-flow: column;
  column-gap: 0.75rem;
  align-items: end;
  display: grid;
`;

export {
  Wrapper,
  Project,
  Scale,
  ProgressBar,
  StatusDetails,
  Icon,
  Current,
  Target,
  Subtitle,
  Description,
  Buttons,
};
