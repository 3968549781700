import HeroVideoMp4 from 'assets/hero.mp4';
import HeroVideoWebm from 'assets/hero.webm';
import PaymentForm from 'components/PaymentForm';

import { Wrapper, Video } from './styles';

const Hero = ({ donation, paymentForm }) => (
  <Wrapper>
    <Video loop muted autoPlay playsInline preload="auto">
      <source src={HeroVideoMp4} type="video/mp4" />
      <source src={HeroVideoWebm} type="video/webm" />
    </Video>

    <PaymentForm
      title={donation?.title}
      subTitle={donation?.subTitle}
      paymentForm={paymentForm}
    />
  </Wrapper>
);

export default Hero;
