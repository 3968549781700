import { HeadingMedium } from 'components/UI/Headings';

import { Container, PhotoReport } from './styles';

const RecentPhotoReports = ({ heading, reports }) => (
  <>
    <HeadingMedium isCentered as="h3">
      {heading}
    </HeadingMedium>
    <Container>
      {reports.edges.map((report, index) => {
        const { link, name, info, image } = report.node;

        return (
          <li key={index}>
            <a href={link || null} rel="noopener noreferrer" target="_blank">
              <PhotoReport image={image} title={name} description={info} />
            </a>
          </li>
        );
      })}
    </Container>
  </>
);

export default RecentPhotoReports;
