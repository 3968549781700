import { StructuredText } from 'react-datocms';
import { usePageLocale } from 'hooks/usePageLocale';

import Image from 'components/Image';
import HelpRequest from 'components/HelpRequest';

import IconOpen from 'assets/projects/open-icon.svg';
import IconShopping from 'assets/projects/shopping-cart-icon.svg';
import IconDelivery from 'assets/projects/delivery-icon.svg';
import IconCheckmark from 'assets/projects/checkmark-icon.svg';

import { HeadingMedium, HeadingSmall } from 'components/UI/Headings';
import { ReverseButton, PrimaryButton } from 'components/UI/Buttons';
import { ScrollableContainer } from 'components/UI/Containers';

import {
  Wrapper,
  Project,
  Scale,
  ProgressBar,
  StatusDetails,
  Icon,
  Current,
  Target,
  Subtitle,
  Description,
  Buttons,
} from './styles';

const StatusLabels = {
  open: {
    text: {
      en: 'collection is in progress',
      uk: 'триває збір',
    },
    icon: <Icon as={IconOpen} />,
  },
  purchase: {
    text: {
      en: 'purchase in progress',
      uk: 'триває покупка',
    },
    icon: <Icon as={IconShopping} />,
  },
  deliver: {
    text: {
      en: 'we are delivering',
      uk: 'доставляємо',
    },
    icon: <Icon as={IconDelivery} />,
  },
  closed: {
    text: {
      en: 'the project is completed',
      uk: 'проєкт завершено',
    },
    icon: <Icon as={IconCheckmark} />,
  },
};

const MainProjects = ({
  heading,
  projects,
  request,
  translations: { donate, viewResults },
}) => {
  const { pageLocale } = usePageLocale();

  return (
    <>
      <HeadingMedium isCentered as="h3">
        {heading}
      </HeadingMedium>
      <Wrapper as={ScrollableContainer}>
        <HelpRequest content={request} />
        {projects.edges.map((project, index) => {
          const {
            title,
            description,
            current,
            target,
            projectStatus,
            currency,
            linkToFundraising,
            linkToFacebookPost,
            gallery,
          } = project.node;
          const result = current / target;
          const isOpen = projectStatus === 'open';
          const isClosed = projectStatus === 'closed';

          return (
            <Project key={index}>
              <div>
                <Scale>
                  <div>
                    {/* eslint-disable react/jsx-one-expression-per-line */}
                    <Current as={HeadingSmall}>
                      {currency}
                      {current.toLocaleString()}
                    </Current>
                    <Target>
                      / {currency}
                      {target.toLocaleString()}
                    </Target>
                  </div>
                </Scale>
                <ProgressBar isClosed={isClosed && true}>
                  <span
                    style={{
                      transform: `scaleX(${isClosed ? target : result})`,
                    }}
                  />
                </ProgressBar>
                <StatusDetails>
                  {StatusLabels[projectStatus].icon}
                  <span>{StatusLabels[projectStatus].text[pageLocale]}</span>
                </StatusDetails>
                <Subtitle>{title}</Subtitle>
                <Description>
                  <StructuredText data={description} />
                </Description>
              </div>
              <Image image={gallery[0]} alt={title} />
              <Buttons>
                {isOpen && (
                  <PrimaryButton
                    as="a"
                    href={linkToFundraising}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  >
                    {donate}
                  </PrimaryButton>
                )}
                {isClosed && linkToFacebookPost && (
                  <ReverseButton
                    as="a"
                    href={linkToFacebookPost}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  >
                    {viewResults}
                  </ReverseButton>
                )}
              </Buttons>
            </Project>
          );
        })}
      </Wrapper>
    </>
  );
};

export default MainProjects;
