import { useState, useEffect } from 'react';
import { HeadingBig, SubHeading } from 'components/UI/Headings';
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons';
import PaymentButton from 'components/PaymentButton';

import { usePageLocale } from 'hooks/usePageLocale';

import ApplePay from 'assets/payment-form/apple-pay.svg';
import GooglePay from 'assets/payment-form/google-pay.svg';
import MasterCard from 'assets/payment-form/mastercard.svg';
import Visa from 'assets/payment-form/visa.svg';

import { PAYMENT_SETTINGS } from '../../constants';

import {
  Wrapper,
  Form,
  Tabs,
  Tab,
  Currency,
  CurrencySelect,
  Contents,
  Content,
  Buttons,
  RegularButtons,
  RegularButtonsItem,
  RegularButton,
  DonateInput,
  PaymentSystems,
} from './payment-form.styled';

const OnePaymentSystems = [<GooglePay />, <ApplePay />, <Visa />, <MasterCard />];
const RegPaymentSystems = [<Visa />, <MasterCard />];

const PaymentForm = ({ title, subTitle, paymentForm }) => {
  const {
    oneTimeText,
    monthlyText,
    donateText,
    chooseYourDonationText,
    swiftCryptoText,
    inputValidationError,
  } = paymentForm;
  const [activeTab, setActiveTab] = useState(0);
  const [amount, setAmount] = useState(100);
  const [currency, setCurrency] = useState(Object.keys(PAYMENT_SETTINGS)[1]);
  const [regIndex, setRegIndex] = useState(0);
  const [paymentSystems, setPaymentSystems] = useState(OnePaymentSystems);
  const [link, setLink] = useState(Object.values(PAYMENT_SETTINGS[currency].range)[0]);

  const notValid = amount < 1;

  const handleClickTab = (e) => {
    // eslint-disable-next-line radix
    const index = parseInt(e.target.id, 0);
    if (index !== activeTab) {
      setActiveTab(index);
    }
  };

  const { pageLocale } = usePageLocale();

  useEffect(() => {
    setLink(Object.values(PAYMENT_SETTINGS[currency].range)[0]);
    setRegIndex(0);
  }, [currency]);

  return (
    <Wrapper>
      <HeadingBig>{title}</HeadingBig>
      <SubHeading>{subTitle}</SubHeading>

      <Form>
        <Tabs>
          <Tab type="button" active={activeTab === 0} onClick={(e) => { handleClickTab(e); setPaymentSystems(OnePaymentSystems); }} id="0">{oneTimeText}</Tab>
          <Tab type="button" active={activeTab === 1} onClick={(e) => { handleClickTab(e); setPaymentSystems(RegPaymentSystems); }} id="1">{monthlyText}</Tab>
        </Tabs>
        <Currency>
          <span>{chooseYourDonationText}</span>
          <CurrencySelect
            defaultValue={currency}
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            {Object.keys(PAYMENT_SETTINGS).map((value, i) => (
              <option key={i} value={value}>{value}</option>
            ))}
          </CurrencySelect>
        </Currency>
        <Contents>
          <Content active={activeTab === 0}>
            <DonateInput currency={PAYMENT_SETTINGS[currency].symbol}>
              <input className={notValid ? 'not-valid' : ''} type="number" defaultValue={amount} onChange={(e) => { setAmount(e.target.value); }} />
              {notValid && <span className="error">{`${inputValidationError} ${PAYMENT_SETTINGS[currency].symbol}1`}</span>}
            </DonateInput>
            <Buttons>
              <PaymentButton
                pageLocale={pageLocale}
                text={donateText}
                amount={amount}
                currency={currency}
              />
              <SecondaryButton as="a" href="#footer">{swiftCryptoText}</SecondaryButton>
            </Buttons>
          </Content>
          <Content active={activeTab === 1}>
            <RegularButtons>
              {Object.keys(PAYMENT_SETTINGS[currency].range).map((value, i) => (
                <RegularButtonsItem key={i}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <RegularButton>
                    <input
                      name="regular"
                      type="radio"
                      value={value}
                      checked={i === regIndex}
                      onChange={() => {
                        setRegIndex(i);
                        setLink(PAYMENT_SETTINGS[currency].range[value]);
                      }}
                    />
                    <span>
                      {`${value} ${PAYMENT_SETTINGS[currency].symbol}`}
                    </span>
                  </RegularButton>
                </RegularButtonsItem>
              ))}
            </RegularButtons>
            <Buttons>
              <PrimaryButton as="a" href={link} target="_blank">
                {donateText}
              </PrimaryButton>
              <SecondaryButton as="a" href="#footer">{swiftCryptoText}</SecondaryButton>
            </Buttons>
          </Content>
          <PaymentSystems>
            {paymentSystems.map((system, key) => (
              <li key={key}>
                {system}
              </li>
            ))}
          </PaymentSystems>
        </Contents>
      </Form>
    </Wrapper>
  );
};

export default PaymentForm;
