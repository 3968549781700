import Photo from 'components/Photo';
import styled from 'styled-components';

import { media } from 'styles/mixins';

const Container = styled.ul`
  padding: 0 var(--container-padding);
  column-count: 4;

  ${media.laptop`
    column-count: 3;
  `}

  ${media.tablet`
    padding-left: var(--container-padding-tablet);
    padding-right: var(--container-padding-tablet);
  `}

  ${media.tabletS`
    column-count: 2;
  `}

  ${media.mobileM`
    column-count: 1;
  `}

  ${media.mobile`
    padding: 0 var(--container-padding-mobile);
  `}

  li {
    margin-bottom: 1rem;

    a {
      display: inherit;
    }
  }
`;

const PhotoReport = styled(Photo)`
  display: inline-block;

  img {
    height: auto;

    ${media.mobile`
      width: 100%;
    `}
  }

  div:last-child {
    margin-top: 0.25rem;
  }
`;

export { Container, PhotoReport };
