import { StructuredText } from 'react-datocms';

import { HeadingSmall } from 'components/UI/Headings';
import { ReverseButton } from 'components/UI/Buttons';

import { Wrapper, Buttons } from './styles';

const HelpRequest = ({
  content: { title, instruction, applyRequestButton, applyRequestForm },
}) => (
  <Wrapper>
    <div>
      <HeadingSmall>{title}</HeadingSmall>
      {instruction?.value && <StructuredText data={instruction} />}
    </div>
    <Buttons>
      <ReverseButton
        as="a"
        target="_blank"
        href={applyRequestForm}
        size="small"
      >
        {applyRequestButton}
      </ReverseButton>
    </Buttons>
  </Wrapper>
);

export default HelpRequest;
