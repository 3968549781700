import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.section`
  grid-auto-flow: column;
  column-gap: var(--gap);
  padding: 0 16.9063rem;
  align-items: center;
  margin-top: 5.5rem;
  display: grid;

  ${media.laptop`
    padding: 0 8.4531rem;
  `}

  ${media.tablet`
    padding: 0 var(--container-padding-tablet);
  `}

  ${media.mobileM`
    row-gap: 2rem;
    margin-top: 2rem;
    grid-auto-flow: row;
    padding: 0 var(--container-padding-mobile);
  `}
`;

const Video = styled.video`
  max-width: 37.3125rem;
  margin-left: auto;
  width: 100%;
`;

export { Wrapper, Video };
