import styled from 'styled-components';
import { HeadingBig, SubHeading } from 'components/UI/Headings';
import { media } from 'styles/mixins';

const Wrapper = styled.div`
  width: var(--form-width);

  ${media.mobileM`
    width: auto;
  `}

  ${HeadingBig} {
    ${media.mobileM`
      text-align: center;
    `}
  }

  ${SubHeading} {
    width: 90%;

    ${media.mobileM`
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    `}
  }
`;

const Form = styled.div`
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px;
  color: rgba(0, 0, 0, 0.6);
  min-height: 17.625rem;
`;

const Tabs = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 16px;
`;

const Tab = styled.button`
  height: 44px;
  flex: 0 0 50%;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;

  ${(props) =>
    props.active &&
    `
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.9);
  `}
`;

const Currency = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0.25rem;
    right: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6L8 10L12 6' stroke='black' stroke-opacity='0.6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: 1rem;
  }
`;

const CurrencySelect = styled.select`
  right: 1rem;
  appearance: none;
  border: none;
  background: transparent;
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 1.25rem;
`;

const Contents = styled.ul``;

const RegularButtons = styled.ul`
  display: flex;
  flex-direction: row;
  height: 2.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
`;

const RegularButtonsItem = styled.li`
  display: flex;
  flex: 0 0 33.333%;
`;

const RegularButton = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;

  input {
    display: none;
  }

  input ~ span {
    display: flex;
    border-radius: 0.1875rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
    background-color: transparent;
  }

  input:hover ~ span {
    color: rgba(0, 0, 0, 0.9);
  }

  input:checked ~ span {
    color: rgba(0, 0, 0, 0.9);
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Content = styled.li`
  ${(props) => (props.active ? '' : 'display:none')}
`;

const DonateInput = styled.div`
  height: 2.75rem;
  position: relative;

  &:before {
    position: absolute;
    display: flex;
    height: 100%;
    left: 0.75rem;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    content: '${(props) => props.currency}';
  }

  input {
    outline-color: transparent;
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 0 1.5rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.not-valid {
      outline-colori: var(--error-color);
      border-color: var(--error-color);
      background-color: var(--error-background);
    }
  }

  .error {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--error-color);
    font-size: 0.75rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 24px;

  > * {
    flex: 0 0 50%;
  }
`;

const PaymentSystems = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: 0.5rem 0 0;

  ${media.mobileM`
    flex-wrap: wrap;
    justify-content: space-evenly;
    
    li {
      margin-bottom: 0.25rem;
    }
  `}
`;

export {
  Wrapper,
  Form,
  Tabs,
  Tab,
  Currency,
  CurrencySelect,
  Contents,
  Content,
  Buttons,
  RegularButtons,
  RegularButtonsItem,
  RegularButton,
  DonateInput,
  PaymentSystems,
};
