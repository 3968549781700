import { StructuredText } from 'react-datocms';

import Image from 'components/Image';

import { HeadingMedium } from 'components/UI/Headings';
import { ScrollableContainer } from 'components/UI/Containers';

import { Wrapper, Partner } from './styles';

function Partners({ heading, partners }) {
  return (
    <>
      <HeadingMedium isCentered as="h3">
        {heading}
      </HeadingMedium>
      <Wrapper as={ScrollableContainer}>
        {partners.edges.map((partner) => {
          const {
            id,
            name,
            image,
            shortDescription: { value },
          } = partner.node;

          return (
            <Partner key={id}>
              <Image image={image} alt={name} />
              <StructuredText data={value} />
            </Partner>
          );
        })}
      </Wrapper>
    </>
  );
}

export default Partners;
