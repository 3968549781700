/* eslint-disable import/no-unresolved */
import { StructuredText } from 'react-datocms';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper';

import Image from 'components/Image';

import { HeadingMedium } from 'components/UI/Headings';

import { Container, GIF } from './styles';

// Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Highlights = ({ highlights: { title, backgroundColor, cards } }) => (
  <>
    <HeadingMedium isCentered as="h3">
      {title}
    </HeadingMedium>
    <Container
      as={Swiper}
      effect="fade"
      modules={[Autoplay, EffectFade, Pagination]}
      slidesPerView={1}
      navigation
      autoplay={{
        delay: 2000,
      }}
      pagination={{
        type: 'progressbar',
      }}
      breakpoints={{
        480: {
          effect: 'slide',
          slidesPerView: 'auto',
          allowTouchMove: false,
          pagination: false,
        },
      }}
    >
      {cards.map(({ id, image, gif, name, description }) => (
        <SwiperSlide key={id} style={{ backgroundColor: backgroundColor.hex }}>
          <Image image={image} alt={name} />
          <GIF src={gif?.url} alt={name} />
          <div>
            <span>{name}</span>
            <StructuredText data={description?.value} />
          </div>
        </SwiperSlide>
      ))}
    </Container>
  </>
);
export default Highlights;
