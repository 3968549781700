import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import OurTeam from 'components/OurTeam';
import Highlights from 'templates/HomePage/Highlights';
import MainProjects from 'templates/HomePage/MainProjects';
import MainPartners from 'templates/HomePage/MainPartners';
import RecentPhotoReports from 'templates/HomePage/RecentPhotoReports';

import { Navigator } from 'components/Layout/Navigator';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { ScrollableContainer } from 'components/UI/Containers';
import { HeadingMedium } from 'components/UI/Headings';

import { MoreInfoLink, Section } from 'styles/elements';

import Hero from './Hero';

const HomepageTemplate = ({
  data: {
    datoCmsHomepage: {
      seo,
      highlights,
      mainProjectsTitle,
      ourTeamTitle,
      recentPhotoReportsTitle,
      morePhotosTitle,
    },
    datoCmsAboutPage: aboutPage,
    datoCmsPartnersPage: partnersPage,
    datoCmsHowToRequest: howToRequest,
    allDatoCmsProject: projects,
    allDatoCmsTeammate: teammates,
    allDatoCmsPartner: partners,
    allDatoCmsPhotoReport: reports,
    datoCmsMiscTextString: translations,
    datoCmsDonation: donation,
    datoCmsPaymentForm: paymentForm,
  },
  pageContext,
}) => {
  const { mainPartners, morePartners, readMoreAboutUs } = translations;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <Section id="donate">
        <Hero donation={donation} paymentForm={paymentForm} />
      </Section>

      <Section>
        <Highlights highlights={highlights[0]} />
      </Section>

      <Section>
        <MainProjects
          heading={mainProjectsTitle}
          projects={projects}
          request={howToRequest}
          translations={translations}
        />
      </Section>

      <Section>
        <Section as="div">
          <HeadingMedium isCentered as="h3">
            {ourTeamTitle}
          </HeadingMedium>
          <ScrollableContainer>
            <OurTeam teammates={teammates} />
          </ScrollableContainer>
        </Section>
        <MoreInfoLink>
          <Navigator recordId={aboutPage.id}>{readMoreAboutUs}</Navigator>
        </MoreInfoLink>
      </Section>

      <Section>
        <Section as="div">
          <MainPartners heading={mainPartners} partners={partners} />
        </Section>
        <MoreInfoLink>
          <Navigator recordId={partnersPage.id}>{morePartners}</Navigator>
        </MoreInfoLink>
      </Section>

      <Section>
        <Section as="div">
          <RecentPhotoReports
            heading={recentPhotoReportsTitle}
            reports={reports}
          />
        </Section>
        <MoreInfoLink>
          <StructuredText data={morePhotosTitle} />
        </MoreInfoLink>
      </Section>
    </PageWrapper>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      highlights {
        title
        backgroundColor {
          hex
        }
        cards {
          id: originalId
          name
          description {
            value
          }
          image {
            gatsbyImageData(imgixParams: {w: "220", h: "220", dpr: 1.5})
          }
          gif {
            url(imgixParams: {h: "220", w: "220", dpr: 1.5})
          }
        }
      }
      mainProjectsTitle
      ourTeamTitle
      recentPhotoReportsTitle
      morePhotosTitle {
        value
      }
    }
    datoCmsDonation(locale: { eq: $locale }) {
      id: originalId
      locale
      title
      subTitle
      fondyLink
    }
    datoCmsAboutPage(locale: { eq: $locale }) {
      id: originalId
    }
    datoCmsPartnersPage(locale: { eq: $locale }) {
      id: originalId
    }
    datoCmsHowToRequest(locale: { eq: $locale }) {
      title
      instruction {
        value
      }
      applyRequestButton
      applyRequestForm
    }
    allDatoCmsProject(
      filter: { locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          current
          target
          title
          currency
          projectStatus
          linkToFundraising
          linkToFacebookPost
          description {
            value
          }
          gallery {
            gatsbyImageData(height: 140)
          }
        }
      }
    }
    allDatoCmsTeammate(
      filter: { locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          fullName
          role
          photo {
            gatsbyImageData(imgixParams: {w: "250", dpr: 2})
          }
        }
      }
    }
    allDatoCmsPartner(
      filter: { locale: { eq: $locale }, mainPartner: { eq: true } }
    ) {
      edges {
        node {
          id
          name
          mainPartner
          shortDescription {
            value
          }
          image {
            url
            format
            gatsbyImageData(height: 70)
          }
        }
      }
    }
    allDatoCmsPhotoReport(
      filter: { locale: { eq: $locale } }
      limit: 20
      sort: { fields: meta___publishedAt, order: ASC }
    ) {
      edges {
        node {
          id
          image {
            format
            gatsbyImageData(width: 400)
          }
          name
          info
          link
        }
      }
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      locale
      readDetails
      viewResults
      donate
      readMoreAboutUs
      otherMethods
      mainPartners
      morePartners
    }
    datoCmsPaymentForm(locale: { eq: $locale }) {
      locale
      oneTimeText
      monthlyText
      chooseYourDonationText
      donateText
      swiftCryptoText
      inputValidationError
    }
  }
`;
