import styled from 'styled-components';

import { Card } from 'styles/elements';

const Wrapper = styled.div`
  grid-auto-columns: min-content;
`;

const Partner = styled(Card)`
  row-gap: var(--gap);
  min-height: 20.5rem;

  img {
    width: auto;
    max-width: 8rem;
    margin: auto auto 0;
    max-height: 4.5rem;
  }

  p {
    font-size: 0.75rem;
    text-align: center;
    color: var(--secondary-text-color);
  }
`;

export { Wrapper, Partner };
